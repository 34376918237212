<template>
  <div :class="{ textPage: $route.params.textPageSlug !== 'contacts' }">
    <section
      :class="$route.params.textPageSlug"
      class="mb"
      v-if="!isUiLocked"
      ref="contentZone"
    >
      <div class="textContainer">
        <the-main-text
          :title="pageTranslate.faq_title"
          :descr="pageTranslate.faq_description"
        ></the-main-text>
      </div>
      <div class="container">
        <accordion :class="{active: activeAccordion}" v-for="(item, idx) in accordionList" :key="idx">
          <accordion-item @activeWrp="activeWrp" class="faqAccordion">
            <template slot="accordion-trigger" id="c1">
              {{ item.model.title }}
              <span class="trigger"></span>
            </template>
            <template slot="accordion-content">
                <li v-html="item.model.description "></li>
            </template>
          </accordion-item>
        </accordion>
      </div>
    </section>
    <div class="container-full" v-if="pageCheck">
      <app-subscribe-form></app-subscribe-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TheMainText from "../components/common/the-main-text.vue";
import AppSubscribeForm from "../components/common/app-subscribe-form.vue";
import Accordion from "../components/common/Accordion.vue";
import AccordionItem from "../components/common/Accordion-item.vue";
export default {
  components: {
    TheMainText,
    AppSubscribeForm,
    Accordion,
    AccordionItem
  },
  data() {
    return {
      pageTranslate: null,
      accordionList: null,
      activeAccordion: false
    };
  },
  methods: {
    activeWrp(state){
        this.activeAccordion = state;
    },
    ...mapActions(["lockUi", "unlockUi"])
  },
  computed: {
    ...mapGetters(["isUiLocked", "globalSetting"]),
    pageCheck() {
      return this.$route.fullPath == "/contacts" ? false : true;
    }
  },
  async created() {
    try {
      this.lockUi();
      const res = await this.axios.post("/api/faq/get-all", {
        lang: this.currentLang,
        slug: this.$route.params.textPageSlug
      });
      this.pageTranslate = res.data.data.translate;
      this.accordionList = res.data.data.items.models;
      document.title =
        res.data.data.translate.faq_meta_title ||
        res.data.data.translate.faq_title ||
        "Dream Travel";
      this.unlockUi();
    } catch (err) {
      // this.$router.push('/404')
      // debugger
      console.log(err.response.status);
      if (err.response.status == "404") {
        this.$router.push({
          name: "NotFound",
          params: {
            locale: this.$i18n.locale == "en" ? null : this.$i18n.locale
          }
        });
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.uiElements
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 50px
    .date
        font-weight: 400
        font-size: 12px
        line-height: 100%
        color: #777777
    ul
        display: flex
        li
            &:first-of-type
                margin-right: 20px !important
                a
                    width: 107px
            &:not(:last-of-type)
                margin-right: 10px
            a
                border: 1px solid $c-btn
                height: 41px
                min-width: 41px
                display: flex
                align-items: center
                justify-content: center
                color: $c-btn
.contacts.mb
    margin-bottom: 0px
.mb
    // margin-bottom: $section-offset
    @media (max-width: 830px)
        margin-bottom: 100px
</style>
<style lang="sass">
.faqAccordion
    border: 2px solid #2A4651
    margin-bottom: 15px
    .accordion__trigger
        font-weight: 600
        font-size: 16px
        padding: 16px
        line-height: 130%
        color: #CACACA
        transition: .3s
        .trigger
            position: absolute
            right: 16px
            // background-color: #fff
            width: 20px
            height: 20px
            &::after, &::before
                content: ''
                background-color: $c-btn
                width: 13px
                position: absolute
                height: 2px
                top: 10px
                left: 50%
                transition: .3s
            &::before
                transform: rotate(90deg)
        &.accordion__trigger_active
            color: $c-btn
            .trigger::before
                transform: rotate(0deg)
    .accordion__content
        font-weight: 300
        font-size: 17px
        line-height: 150%
        color: #CACACA
        margin-bottom: 0px !important
        padding: 0px 16px
        & > *:last-of-type
            padding-bottom: 16px
        p
            margin-bottom: 20px
        li
            ul
                padding-left: 40px
                margin-bottom: 20px
                li
                    font-weight: 300
                    font-size: 17px
                    line-height: 150%
                    position: relative
                    &::before
                        content: ""
                        font-family: "dreamTravel" !important
                        position: absolute
                        left: -40px
                        color: $c-btn
                        width: 16px
                        height: 16px
                        display: flex
                        align-items: center
                        font-size: 10px
                        text-align: center
                        justify-content: center
                        border: 1px solid $c-btn
ol:not([class])
    counter-reset: section
    padding-left: 40px
    li
        position: relative
        &::before
            position: absolute
            counter-increment: section
            content: counters(section,".") " "
            border: 1px solid #4AA7BC
            width: 16px
            height: 16px
            display: flex
            justify-content: center
            align-items: center
            left: -40px
            font-size: 12px
            line-height: 12px
.textContainer
    @media (max-width: 576px)
        padding-left: 25px
        padding-right: 25px
</style>
